import React from "react";
import Button from "../Button";
import history from "../../routes/history";
import { E404, ErrorIcon } from "../../assets/images/svg";
import "./style.css";

function Error404() {
  const goBackHome = () => {
    history.push("/");
    window.location.reload();
  };
  return (
    <div className="error-container flex align-center pd-space-horizontal">
      <div className="text-content">
        <p className="error-code">Oops....</p>
        <h1>Page not found </h1>
        <p className="description">
          This Page doesn`t exist or was removed!
          <br />
          We suggest you back to home.
        </p>
        <div className="button-container">
          <Button onClick={goBackHome} text="Go home" />
        </div>
      </div>
      <div className="illustration">
        <img src={E404} alt="error" className="illu-icon" />
      </div>
    </div>
  );
}

function NoInternet() {
  return (
    <div className="error-container flex align-center space-center pd-space-horizontal">
      <div className="text-content-v flex column align-center">
        <h1>No Internet Connection</h1>
        <div className="button-container">
          <Button onClick={() => window.location.reload()} text="Refresh page" />
        </div>
      </div>
      <div className="illustration-v">
        <img src={ErrorIcon} alt="error" className="illu-icon" />
      </div>
    </div>
  );
}

export default function Error({ state }) {
  return (
    <>
      {state === 1 ? <Error404 /> : null}
      {state === 2 ? <NoInternet /> : null}
    </>
  );
}
