import { Helmet } from "react-helmet";
import Faq from "../../components/Faq";
import { GooglePlay, AppStore } from "../../assets/images/svg";
import Footer from "../../components/Footer";
import "./style.css";

export default function AboutUs() {
  return (
    <div className="main-container">
      <Helmet>
        <title>myPiitch - About Us</title>
      </Helmet>
      <div className="about-container pd-space-md-horizontal">
        <h1>Be Seen, Be Heard, Get hired!</h1>
        <p className="text-secondary">
          myPiitch is the largest video based digital solution for job seekers and employers. It allows job seekers to
          apply and pitch themselves with tailored video resumes. Jobseekers get feedback from employers at every stage
          of the application process. We believe that the traditional ways of job seeking are outdated and a candidate
          cannot be represented by a paper CV only.
        </p>
        <p className="text-secondary">
          myPiitch saves cost and time for job seekers and employers. There is no need to rely on costly Applicant
          Tracking Systems, reviewing piles of CVs and screening numerous candidates, when using myPiitch.
        </p>
        <p className="text-secondary">
          Employers focus on connecting with candidates and driving better hiring experiences. myPiitch also allows
          companies to showcase their value propositions to acquire the best talent in the market. We operate across
          multiple geographies and territories offering diverse talent and job opportunities.
        </p>
      </div>
      <div className="pd-space-md-horizontal">
        <div className="footer-hero footer-hero-talents bg-dark br-32 flex align-center space-center">
          <h1>Ready to stand out?</h1>
          <p>
            Most job seekers wish for an easier way to land their dream job without having to navigate through tons of
            career websites and job portals. myPiitch solves this exact problem.
          </p>
          <div className="hero-store flex align-center">
            <a href="https://play.google.com/store/search?q=mypiitch&c=apps" rel="noreferrer" target="_blank">
              <img src={GooglePlay} alt="google-play" />
            </a>
            <a href="https://www.apple.com/ng/search/mypiitch" rel="noreferrer" target="_blank">
              <img src={AppStore} alt="app-store" />
            </a>
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </div>
  );
}
