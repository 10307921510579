import { Helmet } from "react-helmet";
import { FeatureTal1, FeatureTal2, FeatureTal3, ListCheck, GooglePlay, AppStore } from "../../assets/images/svg";
import { FeatureTal4, FeatureTal5, FeatureTal6 } from "../../assets/images/png";
import Faq from "../../components/Faq";
import Footer from "../../components/Footer";
import "./style.css";
import { AppLinks } from "../../config";

export default function Talents() {
  return (
    <div className="main-container">
      <Helmet>
        <title>myPiitch - Talents</title>
      </Helmet>
      <div className="hero-section hero-section-talents flex align-center pd-space-left">
        <div>
          <h1 className="hero-title text-primary">
            Be seen.
            <br />
            Be heard.
            <br />
            Get hired.
          </h1>
          <p className="hero-subtitle text-secondary">
            Apply to jobs with video resumes, engage and interact with other people with similar interests
          </p>
          <div className="hero-store flex align-center">
            <a href={AppLinks.android} rel="noreferrer" target="_blank">
              <img src={GooglePlay} alt="google-play" />
            </a>
            <a href={AppLinks.ios} rel="noreferrer" target="_blank">
              <img src={AppStore} alt="app-store" />
            </a>
          </div>
        </div>
      </div>
      <div className="pd-space-horizontal flex align-center space-between mb-80 tal">
        <div className="mock-container br-64 mr-99">
          <img src={FeatureTal4} alt="feature-talent-four" />
        </div>
        <div className="mock-texts flex space-center">
          <h1 className="text-primary">Never sell yourself short again</h1>
          <p className="text-primary">
            Get your best foot forward with myPiitch Video Resume. Create an engaging, professional-looking video resume
            in minutes! Simply press record, stop and send application.
          </p>
          <div className="check-list flex align-center mb-8">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">Don’t just tell your story. Show it!</p>
          </div>
          <div className="check-list flex align-center mb-8">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">Apply instantly or save an application to drafts for later</p>
          </div>
          <div className="check-list flex align-center">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">You have 90 seconds, make it count!</p>
          </div>
        </div>
      </div>
      <div className="pd-space-horizontal flex align-center space-between mb-80 row-reverse tal">
        <div className="mock-container br-64 ml-99">
          <img src={FeatureTal5} alt="feature-talent-four" />
        </div>
        <div className="mock-texts flex space-center">
          <h1 className="text-primary">Updates at every stage of your search</h1>
          <p className="text-primary">
            You can now check your job application status at a single glance. You will know what to expect from each
            stage of your job application journey. No more being in the dark as myPiitch will send you real-time updates
            as soon as employers make a decision about your application.
          </p>
          <div className="check-list flex align-center mb-8">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">Application stage updates</p>
          </div>
          <div className="check-list flex align-center mb-8">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">In-app recruiter messaging</p>
          </div>
        </div>
      </div>
      <div className="pd-space-horizontal flex align-center space-between mb-80 tal">
        <div className="mock-container br-64 mr-99">
          <img src={FeatureTal6} alt="feature-talent-four" />
        </div>
        <div className="mock-texts flex space-center">
          <h1 className="text-primary">Discover & engage via circles</h1>
          <p className="text-primary">
            Engage with other job seekers via circles, follow interests and topics you like, post, like and comment on
            user generated content
          </p>
          <div className="check-list flex align-center mb-8">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">Post as yourself or anonymously</p>
          </div>
          <div className="check-list flex align-center mb-8">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">Relevant content tailored to your interests</p>
          </div>
          <div className="check-list flex align-center">
            <img src={ListCheck} alt="list-check" />
            <p className="text-secondary">Large community of other users</p>
          </div>
        </div>
      </div>
      <div className="feature-section pd-space-vertical pd-space-horizontal">
        <h1 className="text-primary">How it works</h1>
        <div className="flex align-start space-between tal-feature">
          <div className="feature-box bg-light-blue br-24">
            <img src={FeatureTal1} alt="feature-talent-one" className="feature-employer" />
            <h1 className="text-primary">Signup</h1>
            <p className="text-secondary">
              Create an account by providing your name, email address and phone number to get started
            </p>
          </div>
          <div className="feature-box bg-light-blue br-24">
            <img src={FeatureTal2} alt="feature-talent-two" className="feature-employer" />
            <h1 className="text-primary">Build your bio</h1>
            <p className="text-secondary">Fill in your education, work history and upload your text based resume.</p>
          </div>
          <div className="feature-box bg-light-blue br-24">
            <img src={FeatureTal3} alt="feature-talent-three" className="feature-employer" />
            <h1 className="text-primary">Start Pitching</h1>
            <p className="text-secondary">
              Ready, set, shoot. Start sending in video pitches to the jobs you’re applying for
            </p>
          </div>
        </div>
      </div>
      <div className="pd-space-md-horizontal">
        <div className="footer-hero footer-hero-talents bg-dark br-32 flex align-center space-center">
          <h1>Ready to stand out?</h1>
          <p>
            Most job seekers wish for an easier way to land their dream job without having to navigate through tons of
            career websites and job portals. myPiitch solves this exact problem.
          </p>
          <div className="hero-store flex align-center">
            <a href={AppLinks.android} rel="noreferrer" target="_blank">
              <img src={GooglePlay} alt="google-play" />
            </a>
            <a href={AppLinks.ios} rel="noreferrer" target="_blank">
              <img src={AppStore} alt="app-store" />
            </a>
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </div>
  );
}
