import { Helmet } from "react-helmet";
import { FeatureEmp1, FeatureEmp2, FeatureEmp3 } from "../../assets/images/svg";
import { FeatureEmp4, FeatureEmp5, FeatureEmp6 } from "../../assets/images/png";
import "./style.css";
import Faq from "../../components/Faq";
import Footer from "../../components/Footer";
import { AppLinks } from "../../config";

export default function Employers() {
  return (
    <div className="main-container">
      <Helmet>
        <title>myPiitch - Employers</title>
      </Helmet>
      <div className="hero-section flex align-center pd-space-left">
        <div>
          <h1 className="hero-title text-primary">
            The better
            <br />
            way to hire talent
          </h1>
          <p className="hero-subtitle text-secondary">Hire faster with video based resumes</p>
          <a href={AppLinks.recruiterNew}>
            <button className="hero-button bg-primary br-12">Publish your Jobs</button>
          </a>
        </div>
      </div>
      <div className="feature-section pd-space-vertical pd-space-horizontal flex align-start space-between">
        <div className="feature-box bg-light-blue br-24">
          <img src={FeatureEmp1} alt="feature-employer-one" className="feature-employer" />
          <h1 className="text-primary">
            Build clear,
            <br />
            concise job ads
          </h1>
          <p className="text-secondary">
            Streamline the hiring process, make better informed decisions and reduce costs. myPiitch allows you to post
            jobs, accept candidate applications, view video and text based resumes, schedule interviews, and more.
          </p>
        </div>
        <div className="feature-box bg-light-blue br-24">
          <img src={FeatureEmp2} alt="feature-employer-two" className="feature-employer" />
          <h1 className="text-primary">Manage your hiring process efficiently</h1>
          <p className="text-secondary">
            Hiring isn't just about making a perfect match — it's also about finding someone who will be an excellent
            fit for your company culture. Scan through video resumes in 90 seconds or less. Make your hiring more
            efficient
          </p>
        </div>
        <div className="feature-box bg-light-blue br-24">
          <img src={FeatureEmp3} alt="feature-employer-three" className="feature-employer" />
          <h1 className="text-primary">
            Access a large pool
            <br />
            of great talent
          </h1>
          <p className="text-secondary">
            Search and sort through all video resumes to find the right candidate for a job opening. Let myPiitch take
            your hiring process up a notch and save your time and money.
          </p>
        </div>
      </div>
      <div className="feature-section-full pd-space-horizontal">
        <div className="feature-box-full bg-light-orange br-60">
          <h1 className="text-primary">Manage the entire hiring process end-to-end</h1>
          <p className="text-secondary">
            With myPiitch, you can easily manage all parts of the hiring process from posting jobs to inviting
            candidates, setting up interviews, and tracking every step. Provide your team with an overview of open jobs
            and who is in what stage of the hiring process.
          </p>
          <img src={FeatureEmp4} alt="feature-employer-four" />
        </div>
      </div>
      <div className="feature-section-full pd-space-horizontal">
        <div className="feature-box-full bg-light-blue br-60">
          <h1 className="text-primary">Who needs e-mail?</h1>
          <p className="text-secondary">
            Stay in touch with prospective applicants via the in-app messaging service that mypiitch provides and reduce
            feedback and overall hiring time.We don’t want to be your go-to resource for boring, text-based resumes
            cluttering up your inbox. And we won’t send you any emails unless you ask us to.
          </p>
          <img src={FeatureEmp5} alt="feature-employer-five" />
        </div>
      </div>
      <div className="feature-section-full pd-space-horizontal">
        <div className="feature-box-full bg-light-green br-60">
          <h1 className="text-primary">Track and update candidate progress</h1>
          <p className="text-secondary">
            Seamlessly collaborate and review resumes with your hiring team in real-time, directly from the pipeline
            page. Watch and rate videos of applicants to make quicker, more informed hiring decisions.
          </p>
          <img src={FeatureEmp6} alt="feature-employer-six" />
        </div>
      </div>
      <div className="pd-space-md-horizontal">
        <div className="footer-hero bg-dark br-32 flex align-center space-center">
          <h1>Ready to get started?</h1>
          <p>Join 100s of other businesses already using myPiitch to power their recruitment processes.</p>
          <a href={AppLinks.recruiterNew}>
            <button className="bg-primary br-12">Create an account</button>
          </a>
        </div>
      </div>
      <Faq />
      <Footer />
    </div>
  );
}
