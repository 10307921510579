import { createContext, useReducer } from "react";
import axios from "axios";
import { toast } from "react-toastify";
export const ResetPasswordContext = createContext();

const initialState = {
  token: "",
  email: "",
  new_password: "",
  isLoading: false
};

const BASE_URL = "https://api.mypiitch.com";

function reducer(state, action) {
  switch (action.type) {
    case "GET_EMAIL":
      return { ...state, email: action.email };
    case "GET_TOKEN":
      return { ...state, token: action.token };
    case "START_LOADING":
      return { ...state, isLoading: true };
    case "END_LOADING":
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

export const ResetPasswordProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onSendResetLink = email => {
    dispatch({ type: "START_LOADING" });
    axios
      .post(`${BASE_URL}/password/reset`, { email })
      .then(response => {
        const { message } = response.data;
        toast.success(message);
        dispatch({ type: "GET_EMAIL", email });
        dispatch({ type: "END_LOADING" });
      })
      .catch(error => {
        dispatch({ type: "END_LOADING" });
        toast.error("Something went wrong, please try again");
      });
  };
  const onResetPassword = password => {
    dispatch({ type: "START_LOADING" });
    axios
      .post(`${BASE_URL}/password/reset/${state.token}`, { new_password: password })
      .then(response => {
        const { message } = response.data;
        toast.success(message);
        dispatch({ type: "END_LOADING" });
      })
      .catch(error => {
        dispatch({ type: "END_LOADING" });
        toast.error("Something went wrong, please try again");
      });
  };
  const verifyToken = token => {
    dispatch({ type: "START_LOADING" });
    axios
      .get(`${BASE_URL}/password/reset/${token}`)
      .then(response => {
        dispatch({ type: "GET_TOKEN", token });
        dispatch({ type: "END_LOADING" });
      })
      .catch(error => {
        dispatch({ type: "END_LOADING" });
        toast.error("Reset link not valid");
      });
  };
  return (
    <ResetPasswordContext.Provider value={{ state, onSendResetLink, onResetPassword, verifyToken }}>
      {children}
    </ResetPasswordContext.Provider>
  );
};
