import { ChevronDown, ChevronRight } from "../../assets/images/svg";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";
import DATUM from "./datum.json";
import "react-accessible-accordion/dist/fancy-example.css";
import "./style.css";

export default function Faq() {
  return (
    <div className="faq bg-light-blue flex align-center">
      <h1>FAQs.</h1>
      <Accordion allowZeroExpanded className="faq-content">
        {DATUM.map((faq, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemState>
                  {({ expanded }) => (
                    <AccordionItemButton className={expanded ? "faq-list faq-list-active" : "faq-list"}>
                      <p className="text-black-01">{faq.title}</p>
                      {expanded ? (
                        <img src={ChevronDown} alt="chevron-down" />
                      ) : (
                        <img src={ChevronRight} alt="chevron-right" />
                      )}
                    </AccordionItemButton>
                  )}
                </AccordionItemState>
              </AccordionItemHeading>
              {faq.content && typeof faq.content === "string" && (
                <AccordionItemPanel className="faq-item">
                  <p>{faq.content}</p>
                </AccordionItemPanel>
              )}
              {faq.content && typeof faq.content !== "string" && (
                <AccordionItemPanel>
                  {faq.content.map((item, index) => {
                    return (
                      <div key={index} style={{ marginBottom: "20px" }}>
                        <p className="text-black-01 faq-sub-title">{item.title}</p>
                        <p className="text-black-01 faq-sub-content">{item.content}</p>
                      </div>
                    );
                  })}
                </AccordionItemPanel>
              )}
              <AccordionItemPanel className="faq-item">
                {faq.items &&
                  faq.items.map((item, index) => {
                    return (
                      <p key={index} style={{ marginBottom: "10px" }}>
                        {index + 1}. {item}
                      </p>
                    );
                  })}
              </AccordionItemPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
}
