import { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/Button";
import { useParams } from "react-router-dom";
import Input from "../../components/Input";
import { ResetPasswordContext } from "../../context/reset";
import "./style.css";

export default function NewPassword() {
  const { state, onResetPassword, verifyToken } = useContext(ResetPasswordContext);
  const [password, setPassword] = useState("");
  const { token } = useParams();
  useEffect(() => {
    verifyToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="main-container">
      <Helmet>
        <title>myPiitch - Reset Password</title>
      </Helmet>
      <div className="form-container pd-space-horizontal">
        <div className="content">
          <h1 className="title">Reset Password</h1>
          <p className="subtitle">Enter your new password</p>
          <p className="label">New password</p>
          <Input getTextValue={value => setPassword(value)} type="password" />
          <Button text="Submit" onClick={() => onResetPassword(password)} loading={state.isLoading} />
        </div>
      </div>
    </div>
  );
}
