import React, { Component, Suspense } from "react";
import Error from "./components/Error";
import Loading from "./components/Loading";
import Navigation from "./components/Navigation";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Employers from "./pages/employers";
import Talents from "./pages/talents";
import AbooutUs from "./pages/about";
import ResetPassword from "./pages/resetpassword";
import history from "./routes/history";
import { ResetPasswordProvider } from "./context/reset";
import NewPassword from "./pages/newpassword";

class App extends Component {
  state = {
    noInternetConnection: false
  };
  componentDidMount() {
    this.handleInternetConnectionChange();
    window.addEventListener("online", this.handleInternetConnectionChange);
    window.addEventListener("offline", this.handleInternetConnectionChange);
  }
  componentWillUnmount() {
    window.removeEventListener("online", this.handleInternetConnectionChange);
    window.removeEventListener("offline", this.handleInternetConnectionChange);
  }
  handleInternetConnectionChange = () => {
    navigator.onLine ? this.setState({ noInternetConnection: false }) : this.setState({ noInternetConnection: true });
  };
  render() {
    if (this.state.noInternetConnection) {
      return <Error state={2} />;
    }
    return (
      <ResetPasswordProvider>
        <Suspense fallback={<Loading />}>
          <Router history={history}>
            <Navigation />
            <Switch>
              <Route exact path="/" component={Talents} />
              <Route path="/employers" component={Employers} />
              <Route path="/about" component={AbooutUs} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route path="/reset-password/:token" component={NewPassword} />
              <Route render={props => <Error state={1} {...props} />} />
            </Switch>
          </Router>
        </Suspense>
      </ResetPasswordProvider>
    );
  }
}

export default App;
