import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import { Logo, Category } from "../../assets/images/svg";
import { AppLinks } from "../../config";
import "./style.css";

export default function Navigation() {
  const [showDropDown, setShowDropDown] = useState(false);
  return (
    <>
      <div className="mp-navigation pd-space-horizontal flex align-center space-between">
        <div className="flex align-center">
          <Link to="/">
            <img src={Logo} alt="mypiitch-logo" onClick={() => setShowDropDown(false)} className="logo" />
          </Link>
          <Link to="/" className="logo-tagline text-primary">
            myPiitch
          </Link>
        </div>
        <div className="flex align-center mp-nav">
          <NavLink to="/employers" activeClassName="text-secondary" className="nav-links text-primary">
            For Employers
          </NavLink>
          <NavLink to="/about" activeClassName="text-secondary" className="nav-links text-primary">
            About Us
          </NavLink>
        </div>
        <div className="flex align-center remove-item-sm">
          <a href={AppLinks.recruiter}>
            <button className="nav-button br-12 bg-primary">Sign in</button>
          </a>
        </div>
        <div className="flex align-center show-item-sm">
          <NavLink to="/employers" activeClassName="text-secondary" className="nav-links text-primary">
            For Employers
          </NavLink>
          <img src={Category} onClick={() => setShowDropDown(!showDropDown)} alt="more" className="nav-more" />
        </div>
      </div>
      {showDropDown ? (
        <div className="nav-drop-down">
          <NavLink
            to="/"
            exact
            activeClassName="text-secondary"
            onClick={() => setShowDropDown(false)}
            className="nav-links text-primary"
          >
            Home
          </NavLink>
          <NavLink
            to="/employers"
            activeClassName="text-secondary"
            onClick={() => setShowDropDown(false)}
            className="nav-links text-primary"
          >
            For Employers
          </NavLink>
          <NavLink
            to="/about"
            activeClassName="text-secondary"
            onClick={() => setShowDropDown(false)}
            className="nav-links text-primary"
          >
            About Us
          </NavLink>
          <a href={AppLinks.recruiter}>
            <button className="nav-button br-12 bg-primary">Sign in</button>
          </a>
        </div>
      ) : null}
    </>
  );
}
