// import { NavLink } from "react-router-dom";
import { Logo } from "../../assets/images/svg";
import "./style.css";

export default function Footer() {
  return (
    <div className="mp-footer pd-space-horizontal flex align-center space-between bg-dark">
      <div className="flex align-center logo">
        <img src={Logo} alt="mypiitch-logo" className="logo" />
      </div>
      <div className="flex align-center mp-foot-nav">
        <p>
          Contact: <a href="tel:info@mypiitch.com">+27 71 292 4198</a>
        </p>
        <p>
          Email: <a href="mailto:info@mypiitch.com">info@mypiitch.com</a>
        </p>
      </div>
      <p className="nav-links" style={{ opacity: 0.8 }}>
        ©2021, myPiitch
      </p>
    </div>
  );
}
