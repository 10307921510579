import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { ResetPasswordContext } from "../../context/reset";
import "./style.css";

export default function ResetPassword() {
  const { state, onSendResetLink } = useContext(ResetPasswordContext);
  const [email, setEmail] = useState("");
  return (
    <div className="main-container">
      <Helmet>
        <title>myPiitch - Reset Password</title>
      </Helmet>
      <div className="form-container pd-space-horizontal">
        <div className="content">
          <h1 className="title">Reset Password</h1>
          <p className="subtitle">
            Enter your email address. If an account exists, we will send you a password reset email.
          </p>
          <p className="label">Email</p>
          <Input getTextValue={value => setEmail(value)} />
          <Button text="Submit" onClick={() => onSendResetLink(email)} loading={state.isLoading} />
        </div>
      </div>
    </div>
  );
}
