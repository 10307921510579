import React, { useState } from "react";
import { Eye } from "../../assets/images/svg";
import "./style.css";

export default function Input({ placeholder, type = "text", getTextValue = () => {}, name, disabled, value }) {
  const [showPassword, setShowPassword] = useState(type);
  const handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    getTextValue(value, name);
  };
  return (
    <div className="input-container">
      <input
        placeholder={placeholder}
        type={showPassword}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        name={name}
        required
      />
      {type === "password" && !disabled ? (
        <div className="right-icon" onClick={() => setShowPassword(showPassword === "text" ? "password" : "text")}>
          <img className="password" src={Eye} alt="eye-password" />
        </div>
      ) : null}
    </div>
  );
}
