import React from "react";
import "./style.css";

export default function Button({ text, onClick, loading }) {
  return (
    <button onClick={loading ? () => {} : onClick}>
      {loading ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        text
      )}
    </button>
  );
}
